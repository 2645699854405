import React, { useState } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import * as config from "./config.js";
// import { Provider } from "react-redux";
// import store from "./redux/store";
// import "react-pro-sidebar/dist/css/styles.css";

const Login = React.lazy(() => import("./views/pages/login/Login"));
const LetterDownlaod = React.lazy(() => import("./views/pages/letterDownload/LetterDownload"));

function App(props) {

  config.setConfig();
  return (
      // <Provider store={store}>
        <BrowserRouter>
          <React.Suspense fallback={""}>
            <div
              className={
                window.location.pathname == "/" ||
                  window.location.pathname == "/login"
                  ? ""
                  : "main"
              }
            >
              <Routes>
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/login`}
                  name="Login"
                  element={<Login />}
                />
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/`}
                  name="Login"
                  element={<Login />}
                />
                <Route
                 exact ={true}
                  path={`${process.env.PUBLIC_URL}/letter-download`}
                  name="Letter download"
                  element={<LetterDownlaod />}
                />
              </Routes>
            </div>
            {/* <NotificationContainer /> */}
          </React.Suspense>
        </BrowserRouter>
      // </Provider>
  );
}

export default App;
